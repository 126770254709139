//
// dialog.tsx
//

import * as Headless from "@headlessui/react";
import { Button as HeadlessUIButton } from "@headlessui/react";
import CloseIcon from "@layouts/svg-icon/dialog-close-icon.svg";
import RecycleIcon from "@layouts/svg-icon/recycle-icon.svg";
import BottomIllustration from "@layouts/svg-illustration/dialog-background.svg";
import clsx from "clsx";
import type React from "react";
import { Alerts, Message } from "./Alerts";
import { Button, ButtonProps } from "./Button";

// #region Dialog

//
// types
//

const sizes = {
  xs: "sm:tw-max-w-xs",
  sm: "sm:tw-max-w-sm",
  md: "sm:tw-max-w-md",
  lg: "sm:tw-max-w-lg",
  xl: "sm:tw-max-w-xl",
  "2xl": "sm:tw-max-w-2xl",
  "3xl": "sm:tw-max-w-3xl",
  "4xl": "sm:tw-max-w-4xl",
  "5xl": "sm:tw-max-w-5xl"
};

//
// component
//

// Dialog Props
export type DialogProps = {
  size?: keyof typeof sizes;
  className?: string;
  children: React.ReactNode;
  beforeLeave?: () => void;
  afterLeave?: () => void;
  beforeEnter?: () => void;
  afterEnter?: () => void;
} & Omit<Headless.DialogProps, "as" | "className">;
export function Dialog({
  size = "lg",
  className,
  children,
  open,
  beforeLeave,
  afterLeave,
  beforeEnter,
  afterEnter,
  ...props
}: DialogProps) {
  return <Headless.Transition show={open} beforeLeave={beforeLeave} afterLeave={afterLeave} beforeEnter={beforeEnter} afterEnter={afterEnter} data-sentry-element="unknown" data-sentry-component="Dialog" data-sentry-source-file="Dialog.tsx">
      <Headless.Dialog {...props} className="tw-relative tw-z-20" data-sentry-element="unknown" data-sentry-source-file="Dialog.tsx">
        <Headless.DialogBackdrop transition className="focus:outline-0 tw-fixed tw-inset-0 tw-flex tw-w-screen tw-justify-center tw-overflow-y-auto tw-bg-zinc-950/25 tw-px-2 tw-py-2 tw-transition tw-duration-100 data-[closed]:tw-opacity-0 data-[enter]:tw-ease-out data-[leave]:tw-ease-in sm:tw-px-6 sm:tw-py-8 lg:tw-px-8 lg:tw-py-16 dark:tw-bg-zinc-950/50" data-sentry-element="unknown" data-sentry-source-file="Dialog.tsx" />

        <div className="tw-fixed tw-inset-0 tw-w-screen tw-overflow-y-auto tw-pt-6 sm:tw-pt-0">
          <div className="tw-grid tw-min-h-full tw-grid-rows-[1fr_auto] tw-justify-items-center sm:tw-grid-rows-[1fr_auto_3fr] sm:tw-p-4">
            <Headless.DialogPanel transition className={clsx(className, sizes[size], "tw-row-start-2 tw-w-full tw-min-w-0 tw-rounded-2xl tw-bg-interface-card-light tw-p-6 tw-shadow-lg tw-ring-1 tw-ring-interface-divider-light [--gutter:theme(spacing.8)] sm:tw-mb-auto dark:tw-bg-interface-card-dark dark:tw-ring-interface-divider-dark forced-colors:tw-outline", "tw-transition tw-duration-100 tw-will-change-transform data-[closed]:tw-translate-y-12 data-[closed]:tw-opacity-0 data-[enter]:tw-ease-out data-[leave]:tw-ease-in sm:data-[closed]:tw-translate-y-0 sm:data-[closed]:data-[enter]:tw-scale-95")} data-sentry-element="unknown" data-sentry-source-file="Dialog.tsx">
              {children}
            </Headless.DialogPanel>
          </div>
        </div>
      </Headless.Dialog>
    </Headless.Transition>;
}
export function DialogTitle({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.DialogTitleProps, "as" | "className">) {
  return <Headless.DialogTitle {...props} className={clsx(className, "tw-text-balance tw-text-h4-desk tw-text-text-title-light dark:tw-text-text-title-dark")} data-sentry-element="unknown" data-sentry-component="DialogTitle" data-sentry-source-file="Dialog.tsx" />;
}
export function DialogDescription({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.DescriptionProps, "as" | "className">) {
  return <Headless.Description {...props} className={clsx(className, "tw-dark:tw-text-text-subTitle-dark tw-mt-4 tw-break-words tw-text-14px-regular tw-text-text-subTitle-light")} data-sentry-element="unknown" data-sentry-component="DialogDescription" data-sentry-source-file="Dialog.tsx" />;
}
export function DialogBody({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={clsx(className, "tw-mt-8")} data-sentry-component="DialogBody" data-sentry-source-file="Dialog.tsx" />;
}
export function DialogActions({
  className,
  layoutType,
  ...props
}: {
  layoutType: "row" | "column";
} & React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={clsx(className, "tw-mt-9 tw-flex tw-flex-col-reverse tw-items-center *:tw-w-full", layoutType === "row" ? "tw-gap-3 sm:tw-flex-row sm:*:tw-w-auto" : "tw-gap-4")} data-sentry-component="DialogActions" data-sentry-source-file="Dialog.tsx" />;
}

// #endregion

// #region GenericDialog

// component

export type GenericDialogProps = {
  dialogClassName?: DialogProps["className"];
  title?: string;
  description?: string;
  body?: React.ReactNode;
  alertMessage?: Message;
  hasBottomIllustration?: boolean;
  submitButtonProps?: ButtonProps;
  secondaryButtonProps?: {
    text: string | React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
  };
  buttonLayoutType?: "row" | "column";
  onClose?: () => void;
} & Omit<DialogProps, "children" | "onClose">;
export function GenericDialog({
  dialogClassName,
  title,
  description,
  body,
  alertMessage,
  hasBottomIllustration = false,
  submitButtonProps,
  secondaryButtonProps,
  buttonLayoutType = "row",
  onClose,
  ...props
}: GenericDialogProps) {
  // Set a default icon for destructive submit button
  const submitButtonPropsWithIcon = submitButtonProps?.variant === "destructive" && submitButtonProps.icon !== null ? {
    ...submitButtonProps,
    icon: submitButtonProps.icon || <RecycleIcon />
  } : submitButtonProps;
  return <Dialog className={dialogClassName} {...props} onClose={(submitButtonProps?.showLoader ? undefined : onClose) || (() => {})} data-sentry-element="Dialog" data-sentry-component="GenericDialog" data-sentry-source-file="Dialog.tsx">
      <div className="tw-flex tw-w-full tw-flex-row tw-justify-between">
        <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="Dialog.tsx">{title}</DialogTitle>

        {!submitButtonProps?.showLoader && onClose && <HeadlessUIButton onClick={onClose}>
            <CloseIcon />
          </HeadlessUIButton>}
      </div>

      <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="Dialog.tsx">{description}</DialogDescription>

      {(body || alertMessage) && <DialogBody className={clsx("tw-flex tw-flex-col tw-gap-4", secondaryButtonProps || submitButtonProps ? "" : "tw-mb-4")}>
          {body}
          {alertMessage && <Alerts messages={[alertMessage]} />}
        </DialogBody>}

      {(secondaryButtonProps || submitButtonProps) && <DialogActions className={buttonLayoutType === "row" && secondaryButtonProps && submitButtonProps ? "tw-justify-between" : "tw-justify-center"} layoutType={buttonLayoutType}>
          {secondaryButtonProps && <HeadlessUIButton className="tw-cursor-pointer tw-text-13px-medium tw-text-brandBlues-brandDark-light dark:tw-text-brandBlues-brandDark-dark" onClick={secondaryButtonProps.onClick} disabled={secondaryButtonProps.disabled}>
              {secondaryButtonProps.text}
            </HeadlessUIButton>}
          {submitButtonProps && <Button {...submitButtonPropsWithIcon} />}
        </DialogActions>}

      {hasBottomIllustration && <div className="tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-0 tw-z-[-1] tw-w-full">
          <BottomIllustration />
        </div>}
    </Dialog>;
}

// #endregion