//
// button.tsx
//

import * as Headless from "@headlessui/react";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { Link } from "./Link";
import { LoadingIndicator } from "./LoadingIndicator";

//
// types
//

export type Sizes = "large" | "medium" | "small";
export type Variants = "primary" | "secondary" | "tertiary" | "icon" | "destructive" | "custom";
type StyleItem = string[];
type SizesStyle = { [K in Sizes]: {
  iconText: StyleItem;
  onlyIcon: StyleItem;
} };
type VariantStyle = { [K in Variants]: {
  base: StyleItem;
  enabled: StyleItem;
  disabled: StyleItem;
} };
interface ButtonStyles {
  base: StyleItem;
  sizes: SizesStyle;
  solid: StyleItem;
  outline: StyleItem;
  plain: StyleItem;
  variants: VariantStyle;
}

//
// component
//

// Define generic styles for all buttons variants
const styles: ButtonStyles = {
  base: [
  // Base
  "tw-relative tw-isolate tw-inline-flex tw-items-center tw-justify-center tw-gap-[theme(spacing[1.5])] tw-rounded-lg",
  // Font
  "tw-text-13px-medium",
  // Focus light
  "focus:tw-outline-none data-[focus]:tw-outline data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-[0.1875rem] data-[focus]:tw-outline-brandBlues-brandSecondary-light",
  // Focus dark
  "tw-dark:data-[focus]:tw-outline-brandBlues-brandSecondary-dark",
  // Icon
  "[&>[data-slot=icon]]:tw-size-4 [&>[data-slot=icon]]:tw-shrink-0 [&>[data-slot=icon]]:tw-text-[--btn-icon] [&>[data-slot=icon]]:sm:tw-my-0 tw-forced-colors:[--btn-icon:ButtonText] tw-forced-colors:data-[hover]:[--btn-icon:ButtonText]",
  // Transition button
  "tw-transition-colors tw-transition-borders tw-duration-200",
  // Transition icon
  "[&>[data-slot=icon]]:tw-transition-colors [&>[data-slot=icon]]:tw-duration-200",
  // Transition before
  "before:tw-transition-colors before:tw-duration-200",
  // Transition after
  "after:tw-transition-colors after:tw-duration-200"],
  sizes: {
    large: {
      iconText: [
      // Sizing
      "tw-h-2xl"],
      onlyIcon: [
      // Sizing
      "tw-size-2xl"]
    },
    medium: {
      iconText: [
      // Sizing
      "tw-h-[2.375rem]"],
      onlyIcon: [
      // Sizing
      "tw-size-[2.375rem]"]
    },
    small: {
      iconText: [
      // Sizing
      "tw-h-8"],
      onlyIcon: [
      // Sizing
      "tw-size-8"]
    }
  },
  solid: [
  // Optical border, implemented as the button background to avoid corner artifacts
  "tw-border-transparent tw-bg-[--btn-border]",
  // Dark mode: border is rendered on `after` so background is set to button background
  "tw-dark:tw-bg-[--btn-bg]",
  // Button background, implemented as foreground layer to stack on top of pseudo-border layer
  "before:tw-absolute before:tw-inset-0 before:-tw-z-10 before:tw-rounded-[calc(theme(borderRadius.lg)-1px)] before:tw-bg-[--btn-bg]",
  // Drop shadow, applied to the inset `before` layer so it blends with the border
  "before:tw-shadow",
  // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
  "tw-dark:before:tw-hidden",
  // Dark mode: Subtle white outline is applied using a border
  "tw-dark:tw-border-white/5",
  // Shim/overlay, inset to match button foreground and used for hover state + highlight shadow
  "after:tw-absolute after:tw-inset-0 after:-tw-z-10 after:tw-rounded-[calc(theme(borderRadius.lg)-1px)]",
  // White overlay on hover
  "after:data-[active]:tw-bg-[--btn-hover-overlay] after:data-[hover]:tw-bg-[--btn-hover-overlay]",
  // Dark mode: `after` layer expands to cover entire button
  "tw-dark:after:-tw-inset-px tw-dark:after:tw-rounded-lg",
  // Disabled
  "before:data-[disabled]:tw-shadow-none after:data-[disabled]:tw-shadow-none"],
  outline: [
  // Base
  "tw-border tw-border-[--btn-border] tw-bg-[--btn-bg] [--btn-bg:theme(colors.transparent)]",
  // Dark mode
  "tw-dark:tw-border-[--btn-border] tw-dark:tw-bg-[--btn-bg] tw-dark:[--btn-bg:theme(colors.transparent)]"],
  plain: [
  // Base
  "tw-border-transparent tw-bg-[--btn-bg] [--btn-bg:theme(colors.transparent)]",
  // Dark mode
  "tw-dark:tw-bg-[--btn-bg] tw-dark:[--btn-bg:theme(colors.transparent)]"],
  variants: {
    primary: {
      base: [],
      enabled: [],
      disabled: []
    },
    secondary: {
      base: [],
      enabled: [],
      disabled: []
    },
    tertiary: {
      base: [],
      enabled: [],
      disabled: []
    },
    icon: {
      base: [],
      enabled: [],
      disabled: []
    },
    destructive: {
      base: [],
      enabled: [],
      disabled: []
    },
    custom: {
      base: [],
      enabled: [],
      disabled: []
    }
  }
};

// Primary Button
styles.variants.primary = {
  base: [...styles.solid,
  // Sizing
  "tw-px-4"],
  enabled: [
  // Colors light default
  "tw-text-interface-card-light [--btn-bg:theme(colors.brandBlues.brandDark.light)] [--btn-border:theme(colors.brandBlues.brandDark.light)] [--btn-icon:theme(colors.brandBlues.cream.light)]",
  // Colors dark default
  "tw-dark:tw-text-interface-card-dark tw-dark:[--btn-bg:theme(colors.brandBlues.brandDark.dark)] tw-dark:[--btn-border:theme(colors.brandBlues.brandDark.dark)] tw-dark:[--btn-icon:theme(colors.brandBlues.cream.dark)]",
  // Colors light hover
  "[--btn-hover-overlay:theme(colors.black/20%)]",
  // Colors dark hover
  "tw-dark:[--btn-hover-overlay:theme(colors.white/20%)]"],
  disabled: [
  // Colors light
  "tw-text-text-body-light [--btn-bg:theme(colors.interface.gray.light)] [--btn-border:theme(colors.interface.gray.light)] [--btn-icon:theme(colors.brandBlues.cream.light)]",
  // Colors dark
  "tw-dark:tw-text-text-body-dark tw-dark:[--btn-bg:theme(colors.interface.gray.dark)] tw-dark:[--btn-border:theme(colors.interface.gray.dark)] tw-dark:[--btn-icon:theme(colors.brandBlues.cream.dark)]"]
};

// Secondary Button
styles.variants.secondary = {
  base: [...styles.solid,
  // Sizing
  "tw-px-4"],
  enabled: [
  // Colors light default
  "tw-text-brandBlues-brandDark-light [--btn-bg:theme(colors.brandBlues.ice.light)] [--btn-border:theme(colors.brandBlues.ice.light)] [--btn-icon:theme(colors.brandBlues.brandDark.light)]",
  // Colors dark default
  "tw-dark:tw-text-brandBlues-brandDark-dark tw-dark:[--btn-bg:theme(colors.brandBlues.ice.dark)] tw-dark:[--btn-border:theme(colors.brandBlues.ice.dark)] tw-dark:[--btn-icon:theme(colors.brandBlues.brandDark.dark)]",
  // Colors light hover
  "[--btn-hover-overlay:theme(colors.interface.brand.light)] data-[hover]:tw-text-brandBlues-cream-light data-[hover]:[--btn-icon:theme(colors.brandBlues.cream.light)]",
  // Colors dark hover
  "tw-dark:[--btn-hover-overlay:theme(colors.interface.brand.light)] tw-dark:data-[hover]:tw-text-brandBlues-cream-dark tw-dark:data-[hover]:[--btn-icon:theme(colors.brandBlues.cream.dark)]"],
  disabled: [
  // Colors light
  "tw-text-text-body-light [--btn-bg:theme(colors.interface.gray.light)] [--btn-border:theme(colors.interface.gray.light)] [--btn-icon:theme(colors.brandBlues.cream.light)]",
  // Colors dark
  "tw-dark:tw-text-text-body-dark tw-dark:[--btn-bg:theme(colors.interface.gray.dark)] tw-dark:[--btn-border:theme(colors.interface.gray.dark)] tw-dark:[--btn-icon:theme(colors.brandBlues.cream.dark)]"]
};

// Tertiary Button
styles.variants.tertiary = {
  base: [
  // Sizing
  "tw-px-4"],
  enabled: [...styles.outline,
  // Colors light default
  "tw-text-brandBlues-brandDark-light [--btn-border:theme(colors.brandBlues.ice.light)] [--btn-icon:theme(colors.brandBlues.brandDark.light)]",
  // Colors light hover
  "data-[hover]:[--btn-bg:theme(colors.brandBlues.pale.light)]",
  // Colors dark default
  "tw-dark:tw-text-brandBlues-brandDark-dark tw-dark:[--btn-border:theme(colors.brandBlues.ice.dark)] [--btn-icon:theme(colors.brandBlues.brandDark.dark)]",
  // Colors dark hover
  "tw-dark:data-[hover]:[--btn-bg:theme(colors.brandBlues.pale.light)]"],
  disabled: [...styles.solid,
  // Colors light
  "tw-text-text-body-light [--btn-bg:theme(colors.interface.gray.light)] [--btn-border:theme(colors.interface.gray.light)] [--btn-icon:theme(colors.brandBlues.cream.light)]",
  // Colors dark
  "tw-dark:tw-text-text-body-dark tw-dark:[--btn-bg:theme(colors.interface.gray.dark)] tw-dark:[--btn-border:theme(colors.interface.gray.dark)] tw-dark:[--btn-icon:theme(colors.brandBlues.cream.dark)]"]
};

// Icon Button
styles.variants.icon = {
  base: [...styles.plain],
  enabled: [
  // Colors light default
  "tw-text-brandBlues-darkBlue-light [--btn-icon:theme(colors.brandBlues.darkBlue.light)]",
  // Colors dark default
  "tw-dark:tw-text-brandBlues-darkBlue-dark tw-dark:[--btn-icon:theme(colors.brandBlues.darkBlue.dark)]",
  // Colors light hover
  "data-[hover]:[--btn-bg:theme(colors.brandBlues.ice.light)]",
  // Colors dark hover
  "tw-dark:data-[hover]:[--btn-bg:theme(colors.brandBlues.ice.dark)]"],
  disabled: [
  // Colors light default
  "[--btn-icon:theme(colors.text.body.light)]",
  // Colors dark default
  " tw-dark:[--btn-icon:theme(colors.text.body.dark)]"]
};

// Destructive Button
styles.variants.destructive = {
  base: [...styles.solid,
  // Sizing
  "tw-px-3"],
  enabled: [
  // Colors light default
  "tw-text-interface-card-light [--btn-bg:theme(colors.semantics.error.light)] [--btn-border:theme(colors.semantics.error.light)] [--btn-icon:theme(colors.interface.card.light)]",
  // Colors dark default
  "tw-dark:tw-text-interface-card-dark tw-dark:[--btn-bg:theme(colors.semantics.error.dark)] tw-dark:[--btn-border:theme(colors.semantics.error.dark)] tw-dark:[--btn-icon:theme(colors.interface.card.dark)]",
  // Colors light hover
  "[--btn-hover-overlay:theme(colors.black/20%)]",
  // Colors dark hover
  "tw-dark:[--btn-hover-overlay:theme(colors.white/20%)]"],
  disabled: [
  // Colors light
  "tw-text-text-body-light [--btn-bg:theme(colors.interface.gray.light)] [--btn-border:theme(colors.interface.gray.light)] [--btn-icon:theme(colors.text.body.light)]",
  // Colors dark
  "tw-dark:tw-text-text-body-dark tw-dark:[--btn-bg:theme(colors.interface.gray.dark)] tw-dark:[--btn-border:theme(colors.interface.gray.dark)] tw-dark:[--btn-icon:theme(colors.text.body.dark)]"]
};
type ButtonTooltipAlignments = "topLeft" | "topRight" | "topMiddle" | "bottomRight" | "bottomLeft" | "bottomMiddle";
type ButtonTooltipProps = {
  text: string;
  classname?: string;
  alignment?: ButtonTooltipAlignments;
};
function ButtonTooltip({
  alignment = "bottomRight",
  classname = "tw-w-44",
  text
}: ButtonTooltipProps) {
  return <span className={clsx(classname, alignment === "topLeft" && "tw-bottom-full tw-left-0 tw-mb-2", alignment === "topRight" && "tw-bottom-full tw-right-0 tw-mb-2", alignment === "topMiddle" && "tw-bottom-full tw-left-1/2 tw-mb-2 tw-translate-x-[-50%]", alignment === "bottomRight" && "tw-right-0 tw-top-full tw-mt-2", alignment === "bottomLeft" && "tw-left-0 tw-top-full tw-mt-2", alignment === "bottomMiddle" && "tw-left-1/2 tw-top-full tw-mt-2 tw-translate-x-[-50%]", "tw-absolute", "tw-rounded-lg tw-px-2 tw-py-2", "tw-whitespace-normal tw-break-words tw-text-center tw-text-12px-regular tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark", "tw-border tw-border-brandBlues-brand-light dark:tw-border-brandBlues-brand-dark", "tw-z-50 tw-bg-interface-card-light dark:tw-bg-interface-card-dark")} data-sentry-component="ButtonTooltip" data-sentry-source-file="Button.tsx">
      {text}
    </span>;
}

// Button Props
export type ButtonBaseProps = {
  variant?: Variants;
  size?: Sizes;
  disabled?: boolean;
  className?: string;
  label?: string;
  icon?: React.ReactNode;
  showLoader?: boolean;
};
export type StandardButtonProps = ButtonBaseProps & Omit<Headless.ButtonProps, "as" | "className" | "children"> & {
  children?: React.ReactNode;
  tooltipOnHover?: ButtonTooltipProps & {
    shouldDisableButton: boolean;
  };
  persistentTooltip?: ButtonTooltipProps;
};
export type LinkButtonProps = ButtonBaseProps & Omit<React.ComponentPropsWithoutRef<typeof Link>, "className"> & {
  tooltipOnHover: never;
  persistentTooltip: never;
};
export type ButtonProps = StandardButtonProps | LinkButtonProps;
export const Button = forwardRef(function Button({
  size = "large",
  variant = "primary",
  className,
  disabled,
  label,
  icon,
  showLoader,
  tooltipOnHover,
  persistentTooltip,
  children,
  ...props
}: ButtonProps, ref: React.ForwardedRef<HTMLElement>) {
  let classes = variant === "custom" ? className : clsx(styles.base, variant === "icon" ? styles.sizes[size].onlyIcon : label ? styles.sizes[size].iconText : styles.sizes[size].onlyIcon, styles.variants[variant].base, className, disabled || tooltipOnHover?.shouldDisableButton ? styles.variants[variant].disabled : styles.variants[variant].enabled);
  return "href" in props ? <Link {...props} className={clsx(classes, "tw-cursor-pointer")} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
      <TouchTarget>
        {icon}
        {label && <span>{label}</span>}
      </TouchTarget>
    </Link> : showLoader ? <Headless.Button disabled={true} {...props} className={clsx(classes, "tw-cursor-pointer")} ref={ref}>
      <TouchTarget>
        <LoadingIndicator />
      </TouchTarget>
    </Headless.Button> : children ? <Headless.Button disabled={disabled} {...props} className={clsx(classes, "tw-cursor-pointer", (tooltipOnHover !== undefined || persistentTooltip !== undefined) && "tw-relative tw-z-50")} ref={ref} onClick={tooltipOnHover?.shouldDisableButton ? undefined : props.onClick}>
      {({
      hover
    }) => <>
          <TouchTarget>{children}</TouchTarget>

          {persistentTooltip && <ButtonTooltip {...persistentTooltip} />}

          {hover && tooltipOnHover && <ButtonTooltip {...tooltipOnHover} />}
        </>}
    </Headless.Button> : <Headless.Button disabled={disabled} {...props} className={clsx(classes, "tw-cursor-pointer", (tooltipOnHover !== undefined || persistentTooltip !== undefined) && "tw-relative tw-z-50")} ref={ref} onClick={tooltipOnHover?.shouldDisableButton ? undefined : props.onClick}>
      {({
      hover
    }) => <>
          <TouchTarget>
            {icon}
            {label && <span>{label}</span>}
          </TouchTarget>

          {persistentTooltip && <ButtonTooltip {...persistentTooltip} />}

          {hover && tooltipOnHover && <ButtonTooltip {...tooltipOnHover} />}
        </>}
    </Headless.Button>;
});

/**
 * Expand the hit area to at least 44×44px on touch devices
 */
export function TouchTarget({
  children
}: {
  children: React.ReactNode;
}) {
  return <>
      <span className="tw-absolute tw-left-1/2 tw-top-1/2 tw-size-[max(100%,2.75rem)] -tw-translate-x-1/2 -tw-translate-y-1/2 [@media(pointer:fine)]:tw-hidden" aria-hidden="true" />
      {children}
    </>;
}