//
// GenericDeleteDialog.tsx
//

import { GenericDialogProps } from "@tw-components/ui/iso";
import { ReactNode } from "react";
import * as Yup from "yup";
import { GenericDialogWithForm } from "./GenericDialogWithForm";
import { GenericInput } from "./Input";

/**
 * Props for the GenericDeleteDialog component.
 *
 * @extends {Omit<GenericDialogProps, "submitButtonProps" | "secondaryButtonProps" | "size" | "buttonLayoutType">}
 *
 * @property {Object} variant - The variant of the confirmation dialog.
 * @property {"with_confirmation" | "no_confirmation"} variant.type - The confirmation type.
 * @property {Object} [variant.firstConfirmation] - Details for the first confirmation step (required for double confirmation).
 * @property {string} variant.firstConfirmation.text - The text to be written by the user in the first confirmation step.
 * @property {string} variant.firstConfirmation.name - The name of the entity to be written by the user in the first confirmation step.
 * @property {string} variant.firstConfirmation.label - The label of the first confirmation input field.
 * @property {string} [variant.secondConfirmationText] - The text to be written by the user in the second confirmation step (only for double confirmation).
 *
 * @property {Object} deleteButton - Configuration for the delete button.
 * @property {string} deleteButton.label - The label for the delete button.
 * @property {function} deleteButton.onClick - The function to be executed when the delete action is confirmed.
 * @property {boolean} [deleteButton.icon] - Icon of the delete button.
 */
type GenericDeleteDialogProps = Omit<GenericDialogProps, "submitButtonProps" | "secondaryButtonProps" | "size" | "buttonLayoutType"> & {
  variant: {
    type: "with_confirmation";
    firstConfirmation: {
      text: string;
      name: string;
      label: string;
    };
    secondConfirmationText?: string;
  } | {
    type: "no_confirmation";
  };
  deleteButton: {
    label: string;
    onClick: (setStatus: (status?: any) => void) => Promise<void>;
    icon?: ReactNode | null;
  };
};

/**
 * A generic delete dialog component that provides single or double confirmation before executing a delete action.
 *
 * @param {GenericDeleteDialogProps} props - The properties passed to the dialog component.
 *
 * @returns {JSX.Element} The rendered delete confirmation dialog.
 */
export function GenericDeleteDialog({
  variant,
  deleteButton,
  body,
  ...props
}: GenericDeleteDialogProps): JSX.Element {
  const validationSchema = variant.type === "with_confirmation" ? Yup.object().shape({
    firstConfirmationText: Yup.string().required(`${variant.firstConfirmation.name} is required.`).oneOf([variant.firstConfirmation.text], `${variant.firstConfirmation.name} must match to the existing ${variant.firstConfirmation.name.toLowerCase()}.`),
    secondConfirmationText: variant.secondConfirmationText ? Yup.string().required("Confirmation text is required.").oneOf([variant.secondConfirmationText], `You must type "${variant.secondConfirmationText}" to confirm.`) : Yup.string()
  }) : undefined;
  return <GenericDialogWithForm<{
    firstConfirmationText: string;
    secondConfirmationText: string;
  }> initialValues={{
    firstConfirmationText: "",
    secondConfirmationText: ""
  }} validationSchema={validationSchema} onSubmit={async (_, {
    setStatus
  }) => {
    await deleteButton.onClick(setStatus);
  }} dialogProps={({
    values,
    handleChange,
    setStatus,
    handleBlur,
    isSubmitting,
    errors
  }) => {
    return {
      ...props,
      body: (() => {
        switch (variant.type) {
          case "no_confirmation":
            return body;
          case "with_confirmation":
            return <div className="tw-flex tw-flex-col tw-gap-4">
                    <GenericInput autoFocus={true} sizeVar="medium" label={variant.firstConfirmation.label} name="firstConfirmationText" value={values.firstConfirmationText} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
                setStatus(undefined);
              }} onBlur={handleBlur} disabled={isSubmitting} errors={errors} />

                    {variant.secondConfirmationText && <GenericInput sizeVar="medium" label={`To verify, type “${variant.secondConfirmationText}” below`} name="secondConfirmationText" value={values.secondConfirmationText} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
                setStatus(undefined);
              }} onBlur={handleBlur} disabled={isSubmitting} errors={errors} />}

                    {body}
                  </div>;
        }
      })(),
      submitButtonProps: {
        label: deleteButton.label,
        variant: "destructive",
        icon: deleteButton.icon,
        size: "medium"
      },
      secondaryButtonProps: props.onClose ? {
        text: "Close",
        onClick: props.onClose
      } : undefined,
      size: variant.type === "with_confirmation" ? "2xl" : "lg",
      buttonLayoutType: "row"
    };
  }} data-sentry-element="GenericDialogWithForm" data-sentry-component="GenericDeleteDialog" data-sentry-source-file="GenericDeleteDialog.tsx" />;
}