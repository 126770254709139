//
// GenericSideSheetWithForm.tsx
//

import { Form, Formik, FormikConfig, FormikProps, FormikValues } from "formik";
import { GenericSideSheet, GenericSideSheetProps } from "./SideSheet";

/**
 * Props for the `GenericSideSheetWithForm` component.
 *
 * @template T - The type of form values used in the side sheet.
 *
 * @extends {FormikConfig<T>}
 *
 * @property {(formikProps: FormikProps<T>) => GenericSideSheetProps} sideSheetProps - Function which returns the props for the dialog.
 */
type GenericSideSheetWithFormProps<T extends FormikValues> = FormikConfig<T> & {
  sideSheetProps: (formikProps: FormikProps<T>) => GenericSideSheetProps;
};

/**
 * A generic side sheet component with form integration using Formik. This component provides
 * an easy way to create side sheet-based forms with validation, form state management, and submission handling.
 *
 * @template T - The type of the form values.
 *
 * @param {GenericSideSheetWithFormProps<T>} props - The properties to configure the side sheet with form support.
 *
 * @returns {JSX.Element} A rendered side sheet with an integrated form.
 */
export function GenericSideSheetWithForm<T extends FormikValues>({
  sideSheetProps,
  onSubmit,
  ...formikConfig
}: GenericSideSheetWithFormProps<T>): JSX.Element {
  return <Formik {...formikConfig} onSubmit={async (values, helpers) => {
    helpers.setStatus(undefined);
    helpers.setSubmitting(true);
    await onSubmit(values, helpers);
    helpers.setSubmitting(false);
  }} data-sentry-element="Formik" data-sentry-component="GenericSideSheetWithForm" data-sentry-source-file="GenericSideSheetWithForm.tsx">
      {formikProps => {
      const {
        body,
        afterLeave,
        alertMessage,
        ...sideSheetConfig
      } = sideSheetProps(formikProps);
      const {
        disabled: submitButtonDisabled,
        onClick: submitButtonOnClick,
        ...submitButtonProps
      } = sideSheetConfig.submitButtonProps ?? {};
      const {
        disabled: secondaryButtonDisabled,
        ...secondaryButtonProps
      } = sideSheetConfig.secondaryButtonProps ?? {};
      return <GenericSideSheet {...sideSheetConfig} afterLeave={afterLeave ? afterLeave : formikProps.resetForm} alertMessage={alertMessage ? alertMessage : formikProps.status} body={body ? <Form>{body}</Form> : undefined} submitButtonProps={sideSheetConfig.submitButtonProps ? {
        ...submitButtonProps,
        onClick: (e: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLAnchorElement>) => {
          if (submitButtonOnClick) {
            submitButtonOnClick(e);
          } else {
            formikProps.handleSubmit();
          }
        },
        disabled: submitButtonDisabled || !formikProps.isValid,
        showLoader: formikProps.isSubmitting
      } : undefined} secondaryButtonProps={sideSheetConfig.secondaryButtonProps ? {
        ...secondaryButtonProps,
        disabled: formikProps.isSubmitting
      } : undefined} />;
    }}
    </Formik>;
}