//
// GenericDialogWithForm.tsx
//

import { Form, Formik, FormikConfig, FormikProps, FormikValues } from "formik";
import { GenericDialog, GenericDialogProps } from "./Dialog";

/**
 * Props for the `GenericDialogWithForm` component.
 *
 * @template T - The type of form values used in the dialog.
 *
 * @extends {FormikConfig<T>}
 *
 * @property {(formikProps: FormikProps<T>) => GenericDialogProps} dialogProps - Function which returns the props for the dialog.
 */
type GenericDialogWithFormProps<T extends FormikValues> = FormikConfig<T> & {
  dialogProps: (formikProps: FormikProps<T>) => GenericDialogProps;
};

/**
 * A generic dialog component with form integration using Formik. This component provides
 * an easy way to create dialog-based forms with validation, form state management, and submission handling.
 *
 * @template T - The type of the form values.
 *
 * @param {GenericDialogWithFormProps<T>} props - The properties to configure the dialog with form support.
 *
 * @returns {JSX.Element} A rendered dialog with an integrated form.
 */
export function GenericDialogWithForm<T extends FormikValues>({
  dialogProps,
  onSubmit,
  ...formikConfig
}: GenericDialogWithFormProps<T>): JSX.Element {
  return <Formik {...formikConfig} onSubmit={async (values, helpers) => {
    helpers.setStatus(undefined);
    await onSubmit(values, helpers);
  }} data-sentry-element="Formik" data-sentry-component="GenericDialogWithForm" data-sentry-source-file="GenericDialogWithForm.tsx">
      {formikProps => {
      const {
        body,
        afterLeave,
        alertMessage,
        ...dialogConfig
      } = dialogProps(formikProps);
      const {
        disabled: submitButtonDisabled,
        onClick: submitButtonOnClick,
        ...submitButtonProps
      } = dialogConfig.submitButtonProps ?? {};
      const {
        disabled: secondaryButtonDisabled,
        text: secondaryButtonText,
        onClick: secondaryButtonOnClick
      } = dialogConfig.secondaryButtonProps ?? {};
      return <GenericDialog {...dialogConfig} afterLeave={afterLeave ? afterLeave : formikProps.resetForm} alertMessage={alertMessage ? alertMessage : formikProps.status} body={body ? <Form>{body}</Form> : undefined} submitButtonProps={dialogConfig.submitButtonProps ? {
        ...submitButtonProps,
        onClick: (e: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLAnchorElement>) => {
          if (submitButtonOnClick) {
            submitButtonOnClick(e);
          } else {
            formikProps.handleSubmit();
          }
        },
        disabled: submitButtonDisabled || !formikProps.isValid,
        showLoader: formikProps.isSubmitting
      } : undefined} secondaryButtonProps={secondaryButtonText && secondaryButtonOnClick ? {
        text: secondaryButtonText,
        onClick: secondaryButtonOnClick,
        disabled: secondaryButtonDisabled || formikProps.isSubmitting
      } : undefined} />;
    }}
    </Formik>;
}