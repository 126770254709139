"use client";

import * as Headless from "@headlessui/react";
import ChevronLeftIcon from "@layouts/svg-icon/chevron-left-icon.svg";
import ChevronRightIcon from "@layouts/svg-icon/chevron-right-icon.svg";
import clsx from "clsx";
import React, { createContext, forwardRef, useContext, useEffect, useRef, useState } from "react";
const TableContext = createContext<{
  variant: "bare" | "outlined" | "custom";
}>({
  variant: "bare"
});
export function TableHead({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"thead">) {
  return <thead {...props} className={clsx(className, "tw-text-text-title-light dark:tw-text-text-title-dark")} data-sentry-component="TableHead" data-sentry-source-file="Table.tsx" />;
}
export function TableBody(props: React.ComponentPropsWithoutRef<"tbody">) {
  return <tbody {...props} data-sentry-component="TableBody" data-sentry-source-file="Table.tsx" />;
}
export function TableFooter(props: React.ComponentPropsWithoutRef<"tfoot">) {
  return <tfoot {...props} data-sentry-component="TableFooter" data-sentry-source-file="Table.tsx" />;
}
export const TableRow = forwardRef(function TableRow({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"tr">, ref: React.ForwardedRef<HTMLTableRowElement>) {
  const {
    variant
  } = useContext(TableContext);
  return <tr {...props} ref={ref} className={clsx(className, variant === "outlined" && ["tw-relative", "after:tw-absolute last:after:tw-hidden", "after:tw-bottom-0 after:tw-left-6 after:tw-right-6 after:tw-h-px", "after:tw-bg-interface-divider-light after:dark:tw-bg-interface-divider-dark"], variant === "bare" && "tw-border-b tw-border-interface-gray-light dark:tw-border-interface-gray-dark")} />;
});
export function TableHeader({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"th">) {
  let {
    variant
  } = useContext(TableContext);
  return <th {...props} className={clsx(className, variant === "outlined" && "tw-px-6 tw-py-5", variant === "bare" && "tw-py-4", variant === "outlined" && "tw-bg-interface-innerCard-light dark:tw-bg-interface-innerCard-dark", variant === "outlined" && "tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark", variant === "bare" && "tw-font-sans tw-text-14px-medium tw-text-mediumblue")} data-sentry-component="TableHeader" data-sentry-source-file="Table.tsx" />;
}
export function TableCell({
  className,
  children,
  ...props
}: React.ComponentPropsWithoutRef<"td">) {
  let {
    variant
  } = useContext(TableContext);
  return <td {...props} className={clsx(className, "tw-relative", variant === "bare" && "tw-py-4", variant === "outlined" && "tw-px-6 tw-py-5")} data-sentry-component="TableCell" data-sentry-source-file="Table.tsx">
      {children}
    </td>;
}
export type TableProps = {
  variant?: "bare" | "outlined" | "custom";
} & React.ComponentPropsWithoutRef<"div">;
export function Table({
  className,
  children,
  variant = "bare",
  ...props
}: TableProps) {
  return <TableContext.Provider value={{
    variant
  } as React.ContextType<typeof TableContext>} data-sentry-element="unknown" data-sentry-component="Table" data-sentry-source-file="Table.tsx">
      <div className={clsx("tw-flow-root tw-overflow-hidden", variant === "outlined" && "tw-rounded-2xl tw-border tw-border-interface-divider-light dark:tw-border-interface-divider-dark")}>
        <div {...props} className={clsx(className, "tw--mx-[--gutter] tw-overflow-x-auto tw-whitespace-nowrap")}>
          <div className={clsx("tw-inline-block tw-min-w-full tw-align-middle", "sm:tw-px-[--gutter]")}>
            <table className={clsx("tw-min-w-full tw-text-left tw-text-14px-regular", variant === "bare" && "tw-font-mono tw-text-black", variant === "outlined" && "tw-text-mediumblue")}>
              {children}
            </table>
          </div>
        </div>
      </div>
    </TableContext.Provider>;
}
export type Column<T, K extends keyof T = keyof T> = {
  key: K;
  label: React.ReactNode;
  render?: (value: T[K]) => React.ReactNode;
};
export type GenericTableWithPaginationProps<T> = {
  data: T[];
  columns: Column<T>[];
  fixedRowCount?: number;
} & Omit<TableProps, "children">;
export function GenericTableWithPagination<T>({
  data,
  columns,
  variant,
  fixedRowCount,
  ...props
}: GenericTableWithPaginationProps<T>) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(fixedRowCount ? fixedRowCount : data.length);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const firstRowRef = useRef<HTMLTableRowElement>(null);
  const headerRowRef = useRef<HTMLTableRowElement>(null);
  const [rowHeight, setRowHeight] = useState(48);
  const [headerHeight, setHeaderHeight] = useState(48);
  useEffect(() => {
    if (fixedRowCount !== undefined) return;
    const updateRowHeight = () => {
      if (firstRowRef.current) {
        const actualHeight = firstRowRef.current.getBoundingClientRect().height;
        setRowHeight(actualHeight || 48);
      }
    };
    updateRowHeight();
    window.addEventListener("resize", updateRowHeight);
    return () => window.removeEventListener("resize", updateRowHeight);
  }, [data]);
  useEffect(() => {
    if (fixedRowCount !== undefined) return;
    const updateHeaderHeight = () => {
      if (headerRowRef.current) {
        const actualHeight = headerRowRef.current.getBoundingClientRect().height;
        setHeaderHeight(actualHeight || 48);
      }
    };
    updateHeaderHeight();
    window.addEventListener("resize", updateHeaderHeight);
    return () => window.removeEventListener("resize", updateHeaderHeight);
  }, [columns]);
  useEffect(() => {
    if (fixedRowCount !== undefined) return;
    const calculateRowsPerPage = () => {
      if (tableContainerRef.current) {
        const availableHeight = tableContainerRef.current.parentElement?.clientHeight || 0;
        const calculatedRows = availableHeight ? Math.max(Math.floor((availableHeight - headerHeight - rowHeight) / rowHeight), 1) : data.length;
        setRowsPerPage(calculatedRows > 0 ? calculatedRows : 1);
      }
    };
    calculateRowsPerPage();
    window.addEventListener("resize", calculateRowsPerPage);
    return () => window.removeEventListener("resize", calculateRowsPerPage);
  }, [data, rowHeight, headerHeight]);
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  return <div ref={tableContainerRef} data-sentry-component="GenericTableWithPagination" data-sentry-source-file="Table.tsx">
      <Table {...props} variant={variant} data-sentry-element="Table" data-sentry-source-file="Table.tsx">
        <TableHead data-sentry-element="TableHead" data-sentry-source-file="Table.tsx">
          <TableRow ref={headerRowRef} data-sentry-element="TableRow" data-sentry-source-file="Table.tsx">
            {columns.map(col => <TableHeader key={String(col.key)}>{col.label}</TableHeader>)}
          </TableRow>
        </TableHead>

        <TableBody data-sentry-element="TableBody" data-sentry-source-file="Table.tsx">
          {paginatedData.map((row, rowIndex) => <TableRow key={rowIndex} ref={rowIndex === 0 ? firstRowRef : undefined}>
              {columns.map(col => <TableCell key={String(col.key)}>
                  {col.render ? col.render(row[col.key]) : String(row[col.key])}
                </TableCell>)}
            </TableRow>)}

          {rowsPerPage && totalPages > 1 && <TableRow className={variant === "bare" ? "tw-border-b-0" : undefined}>
              <TableCell colSpan={columns.length}>
                <div className="tw-flex tw-items-center tw-justify-end tw-gap-3 tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark">
                  {currentPage !== 1 && <Headless.Button onClick={() => goToPage(currentPage - 1)}>
                      <ChevronLeftIcon />
                    </Headless.Button>}

                  <div className={clsx("tw-text-14px-medium", currentPage === totalPages ? "tw-pr-8" : "")}>
                    Page {currentPage} of {totalPages}
                  </div>

                  {currentPage !== totalPages && <Headless.Button onClick={() => goToPage(currentPage + 1)}>
                      <ChevronRightIcon />
                    </Headless.Button>}
                </div>
              </TableCell>
            </TableRow>}
        </TableBody>
      </Table>
    </div>;
}